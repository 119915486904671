import { Profile, ProfileConfig } from '@cca/util';

import Environment from '../../Environment.type';

import development from './development';
import production from './production';
import qa from './qa';

const profileConfig: ProfileConfig<Environment> = {
  name: Profile.mmbe,
  development,
  qa,
  production,
};

export default profileConfig;
