import env from '@beam-australia/react-env';

import { Env, Profile, selectEnvironment } from '@cca/util';

import mmatProfile from './profiles/mmat';
import mmbeProfile from './profiles/mmbe';
import mmdeProfile from './profiles/mmde';
import mmesProfile from './profiles/mmes';
import mmnlProfile from './profiles/mmnl';
import sedeProfile from './profiles/sede';

const environment = selectEnvironment(
  {
    mmde: mmdeProfile,
    sede: sedeProfile,
    mmes: mmesProfile,
    mmat: mmatProfile,
    mmnl: mmnlProfile,
    mmbe: mmbeProfile,
  },
  env('CCA_PROFILE') as Profile,
  env('CCA_ENV') as Env,
);

export default environment;
